<template>
  <div class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <div class="page__body__header">
        <div class="row">
          <div class="col-6" v-if="!isLoading">
            <label
              for="text"
              class="has-checkbox page__body__header__label text--capital"
              >Show deactivated branches
              <input
                type="checkbox"
                id="text"
                v-model="showDeactivated"
                @click="switchShowDeactivated()"
                class="form-"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="col-6" v-if="isLoading"></div>
          <div class="col-6 d-flex justify-content-end" v-if="userCanCreateBranches">
            <button
              type="button"
              data-toggle="modal"
              data-target="#newBranchModal"
              @click="clearForm"
              class="
                button
                page__body__header__button
                button--grey
                text--capital
              "
            >
              <span class="d-inline-flex mr-2">add new branch</span>
              <ion-icon name="add-outline" class="text--medium"></ion-icon>
            </button>
          </div>
        </div>
      </div>
      <table class="customtable w-100">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 20%"
            >
              branch name & ID
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 10%"
            >
              branch state
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              created
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              last modified
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in allBranches.items" v-bind:key="item.branchKey">
            <td>
              <div class="align-self-center" style="padding-right: 15px;">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                  style="word-break: break-word;"
                >
                  {{ item.branchName }}
                </span>
                <span
                  class="
                    table__body__text table__text
                    d-block
                    py-1
                    table__text--small
                    text--capital
                  "
                  style="word-break: break-all;"
                >
                  {{ item.branchId }}
                </span>
              </div>
            </td>
            <td>
              <span
                v-if="item.branchState == 'Active'"
                class="
                  table__body__text table__text
                  text--capital text--success
                "
                >{{ item.branchState }}</span
              >
              <span
                v-if="item.branchState == 'Inactive'"
                class="table__body__text table__text text--capital text--danger"
                >deactivated</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                item.creationDate | moment
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                item.lastModifiedDate | moment
              }}</span>
            </td>
            <td>
              <div class="align-self-center dropdown">
                <button
                  class="
                    button button--grey
                    text--capital text--small
                    d-flex
                    align-items-center
                  "
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    href="#"
                    class="dropdown-item text--capital"
                    type="button"
                    data-toggle="modal"
                    data-target="#editModal"
                    v-on:click="editBranch(item)"
                    v-if="userCanEditBranches"
                    >Edit</a
                  >
                  <a
                    v-if="item.branchState == 'Active' && userCanDeactivateBranches"
                    href="#"
                    class="dropdown-item text--capital"
                    type="button"
                    data-toggle="modal"
                    data-target="#activationConfirmModal"
                    v-on:click="openActivationConfirmation(item, 'deactivate')"
                  >
                    <span class="text-danger">deactivate</span>
                  </a>
                  <a
                    v-if="item.branchState == 'Inactive' && userCanActivateBranches"
                    href="#"
                    class="dropdown-item text--capital"
                    type="button"
                    data-toggle="modal"
                    data-target="#activationConfirmModal"
                    v-on:click="openActivationConfirmation(item, 'activate')"
                  >
                    <span class="text--success">activate</span>
                  </a>
                  <!-- <a
                    class="button form__button btn-danger"
                    type="button"
                    data-toggle="modal"
                    data-target="#deleteConfirmModal"
                    v-on:click="openDeleteConfirmation(item)"
                  >
                    Delete
                  </a> -->
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      
      <div class="pagination">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            showing
            <span class="d-inline-block text--primary mr-3">
              <label for="">
                <select
                  v-model.number="fetchModel.pageSize"
                  class="select select--lg select--page"
                  @change="getBranches()"
                >
                  <option
                    v-for="psize in pagesizes"
                    :key="psize.id"
                    :id="psize.id"
                    :selected="fetchModel.pageSize"
                  >
                    {{ psize.size }}
                  </option>
                </select>
              </label>
            </span>
            <span class="d-inline-block text--primary">{{
              allBranches.currentPage
            }}</span>
            to
            <span class="d-inline-block text--primary">{{
              allBranches.totalPages
            }}</span>
            <!-- of
            <span>{{ allBranches.totalCount }}</span> -->
          </div>

          <div
            class="
              pagination__buttons
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <button
              class="button button--sm text--regular"
              v-if="allBranches.hasPreviousPage"
              @click="changeCurrentPage('prev')"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <button
              class="button text--regular button--sm ml-2"
              v-for="pages in allBranches.totalPages"
              :key="pages"
              :class="{
                'button--primary text--white': allBranches.pageIndex == pages || allBranches.currentPage === pages,
              }"
              @click="changeCurrentPage(pages)"
            >
              {{ pages }}
            </button>
            <button
              class="button text--regular button--sm ml-2"
              v-if="allBranches.hasNextPage"
              @click="changeCurrentPage('next')"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals  -->
    <div
      class="modal modal__right"
      id="newBranchModal"
      tabindex=""
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="#newBranchModal"
                class="modal__close modal__close--black"
              >
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="formCloseButton"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">New branch</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveForm">
              <div class="form__item">
                <label class="form__label">branch name</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter branch name"
                  v-model="form.branchName"
                  v-bind:class="{
                    'input-required': formErr.branchName,
                  }"
                />
              </div>
              <div class="form__item">
                <label class="form__label">branch email address</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter branch email address"
                  v-model="form.branchEmailaddress"
                  @input="validateEmailAddress(form.branchEmailaddress)"
                  v-bind:class="{
                    'input-required': formErr.branchEmailaddress,
                  }"
                />
                <p class="text--danger" v-if="formErr.branchEmailaddress">Please provide a valid email</p>
              </div>
              <div class="form__item">
                <label class="form__label">branch id</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter branch id"
                  v-model="form.branchId"
                  v-bind:class="{
                    'input-required': formErr.branchId,
                  }"
                  :maxlength="20"
                  :size="20"
                />
              </div>
              <div class="form__item">
                <label class="form__label">branch phone number</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter branch phone number"
                  v-model="form.branchPhonenumber"
                />
              </div>
              <div class="form__item">
                <label class="form__label">branch notes</label>
                <div id="content-container">
                  <textarea
                    v-model="form.branchNotes"
                    class="input form__input w-100"
                    placeholder=" Branch notes"
                    cols="100"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div class="form__item">
                <label class="form__label">branch state</label>
                <select
                  class="select--lg w-100"
                  v-model="form.branchState"
                  v-bind:class="{
                    'input-required': formErr.branchState,
                  }"
                >
                  <option disabled>Select option</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <ul id="accordion" class="mb5">
                <div
                  class="modal__accordion"
                  v-for="(set, index) in customFields"
                  :key="set.customFieldSetKey"
                >
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    :data-target="`#ccSet${index}`"
                    aria-expanded="false"
                    aria-controls="ccprofileNote"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      {{ set.customFieldSetName }}
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    :id="`ccSet${index}`"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="row">
                        <div
                          class="col-6"
                          v-for="form in set.customFields"
                          :key="form.customFieldKey"
                        >
                          <div class="form__item">
                            <label class="form__label">{{
                              form.customFieldName
                            }}</label>
                            <input
                              :type="
                                form.dataType === 'Number'
                                  ? 'number'
                                  : form.dataType === 'Date'
                                  ? 'date'
                                  : form.dataType === 'Checkbox'
                                  ? 'checkbox'
                                  : 'text'
                              "
                              v-if="form.dataType !== 'Selection'"
                              :id="'new-' + form.customFieldKey"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              :placeholder="`Enter ${form.customFieldName}`"
                            />
                            <select
                              class="select--lg w-100"
                              v-if="form.dataType === 'Selection'"
                              :id="'new-' + form.customFieldKey"
                            >
                              <option disabled selected value="null">
                                Select {{ form.customFieldName }}
                              </option>
                              <option
                                v-for="opt in form.customFieldSelections"
                                :key="opt.customFieldSelectionKey"
                                :value="opt.value"
                              >
                                {{ opt.value }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>

              <div class="form__item">
                <div class="form__action">
                  <button
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    type="submit"
                    v-if="!isSaving"
                    :disabled="buttonDisabled"
                  >
                    save changes
                  </button>
                  <!-- loader button -->
                  <a
                    role="button"
                    class="
                      button
                      form__button form__button--lg
                      w-25
                      save-changes
                    "
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                    @click="clearForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="#editModal"
                class="modal__close modal__close--black"
              >
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="formCloseButton2"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">edit branch</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveEditForm">
              <div class="form__item">
                <label class="form__label">branch name</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter branch name"
                  v-model="editForm.branchName"
                  v-bind:class="{
                    'input-required': editFormErr.branchName,
                  }"
                />
              </div>
              <div class="form__item">
                <label class="form__label">branch id</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter branch id"
                  v-model="editForm.branchId"
                  v-bind:class="{
                    'input-required': editFormErr.branchId,
                  }"
                  :maxlength="20"
                />
              </div>
              <div class="form__item">
                <label class="form__label">branch email address</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter branch email address"
                  v-model="editForm.branchEmailaddress"
                  v-bind:class="{
                    'input-required': editFormErr.branchEmailaddress,
                  }"
                />
              </div>
              <div class="form__item">
                <label class="form__label">branch phone number</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter branch phone number"
                  v-model="editForm.branchPhonenumber"
                />
              </div>
              <div class="form__item">
                <label class="form__label">branch notes</label>
                <div id="content-container">
                  <textarea
                    v-model="editForm.branchNotes"
                    class="input form__input w-100"
                    placeholder=" Branch notes"
                    cols="100"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div class="form__item">
                <label class="form__label">branch state</label>
                <select
                  class="select--lg w-100"
                  v-model="editForm.branchState"
                  v-bind:class="{
                    'input-required': editFormErr.branchState,
                  }"
                >
                  <option disabled>Select option</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>

              <ul id="accordion" class="mb5">
                <div
                  class="modal__accordion"
                  v-for="(set, index) in customFields"
                  :key="set.customFieldSetKey"
                >
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    :data-target="`#ccSet${index}`"
                    aria-expanded="false"
                    aria-controls="ccprofileNote"
                  >
                    <h5
                      class="
                        w-100
                        mb-0
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      {{ set.customFieldSetName }}
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    :id="`ccSet${index}`"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="row">
                        <div
                          class="col-6"
                          v-for="form in set.customFields"
                          :key="form.customFieldKey"
                        >
                          <div class="form__item">
                            <label class="form__label">{{
                              form.customFieldName
                            }}</label>
                            <input
                              :type="
                                form.dataType === 'Number'
                                  ? 'number'
                                  : form.dataType === 'Date'
                                  ? 'date'
                                  : form.dataType === 'Checkbox'
                                  ? 'checkbox'
                                  : 'text'
                              "
                              v-if="form.dataType !== 'Selection'"
                              :id="form.customFieldKey"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              :placeholder="`Enter ${form.customFieldName}`"
                            />
                            <select
                              class="select--lg w-100"
                              v-if="form.dataType === 'Selection'"
                              :id="form.customFieldKey"
                            >
                              <option disabled selected value="null">
                                Select {{ form.customFieldName }}
                              </option>
                              <option
                                v-for="opt in form.customFieldSelections"
                                :key="opt.customFieldSelectionKey"
                                :value="opt.value"
                              >
                                {{ opt.value }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>

              <div class="form__item">
                <div class="form__action">
                  <button
                    class="
                      button
                      form__button form__button--lg
                      w-30
                      save-changes
                    "
                    type="submit"
                    v-if="!isSaving"
                  >
                    save changes
                  </button>
                  <!-- loader button -->
                  <a
                    role="button"
                    class="
                      button
                      form__button form__button--lg
                      w-25
                      save-changes
                    "
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                    @click="clearForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__confirm"
      id="activationConfirmModal"
      aria-hidden="true"
    >
      <ion-icon
        class="modal__head__icon modal__close"
        name="close-outline"
        data-dismiss="modal"
        aria-label="Close"
      ></ion-icon>
      <div class="">
        <div
          class="modal__dialog"
          role="document"
          v-if="activationBranch != null"
        >
          <div class="modal__head">
            <div
              class="modal__close modal__head__icon modal__close--white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span> <ion-icon name="close-outline"></ion-icon> Close</span>
            </div>

            <h5 class="modal__header__title">Confirmation</h5>
          </div>
          <div class="modal__content">
            <div class="modal__header">
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Are you sure you want to {{ activationType }} this branch '{{
                    activationBranch.branchName
                  }}' ?
                </h5>
              </div>
              <div class="modal__header__subtitle">
                <h6 class="my-4"></h6>
              </div>
            </div>

            <div>
              <button
                class="
                  button
                  form__button form__button--lg
                  mr-5
                  modal__confirm__button modal__confirm__button--cancel
                "
                data-dismiss="modal"
                aria-label="Close"
                id="activationCloseButton"
              >
                Cancel
              </button>

              <!-- loader button -->
              <a
                role="button"
                class="
                  button
                  form__button form__button--lg
                  w-25
                  modal__confirm__button modal__confirm__button--delete
                "
                v-if="isActivating"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="
                  button
                  form__button form__button--lg
                  modal__confirm__button modal__confirm__button--delete
                "
                type="submit"
                v-if="!isActivating"
                v-on:click="activateBranch()"
              >
                {{ activationType }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__confirm"
      id="deleteConfirmModal"
      aria-hidden="true"
    >
      <ion-icon
        class="modal__head__icon modal__close"
        name="close-outline"
        data-dismiss="modal"
        aria-label="Close"
      ></ion-icon>
      <div class="">
        <div
          class="modal__dialog"
          role="document"
          v-if="branchToDelete != null"
        >
          <div class="modal__head">
            <div
              class="modal__close modal__head__icon modal__close--white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span> <ion-icon name="close-outline"></ion-icon> Close</span>
            </div>

            <h5 class="modal__header__title">Confirmation</h5>
          </div>
          <div class="modal__content">
            <div class="modal__header">
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Are you sure you want to delete this branch
                  {{ branchToDelete.branchName }} ?
                </h5>
              </div>
              <div class="modal__header__subtitle">
                <h6 class="my-4">
                  When you delete this branch from your list, you won't be able
                  to access it again,
                </h6>
              </div>
            </div>

            <div>
              <button
                class="
                  button
                  form__button form__button--lg
                  mr-5
                  modal__confirm__button modal__confirm__button--cancel
                "
                data-dismiss="modal"
                aria-label="Close"
                id="deleteCloseButton"
              >
                Cancel
              </button>

              <!-- loader button -->
              <a
                role="button"
                class="
                  button
                  form__button form__button--lg
                  w-25
                  modal__confirm__button modal__confirm__button--delete
                "
                v-if="isDeleting"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="
                  button
                  form__button form__button--lg
                  modal__confirm__button modal__confirm__button--delete
                "
                type="submit"
                v-if="!isDeleting"
                v-on:click="deleteBranch()"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal
      :show="!userCanViewBranches"
      :title="'Unauthorized'"
      @close="goToDashboard"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">
              Unauthorized
            </h5>
            <!-- <h5 class="text-danger">
              You don
            </h5> -->
            <div class="d-flex mt-5">

              <button
                data-dismiss="#confirmModal"
                class="
                  button
                  form__button form__button--lg
                  save-changes
                  fitrem
                "
                @click.prevent="goToDashboard"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import branchesService from "@/core/services/organization-service/branches.service";
import fieldSetService from "@/core/services/fieldset-service/fieldset.service";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import moment from "moment";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";

export default Vue.extend({
  name: "OrganizationBranches",
  components: {
    DeleteModal,
  },
  data: function () {
    return {
      content_header: {
        title: "Branches",
        subTitle: "",
      },
      branchStates: ["Active", "Inactive"],
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      showDeactivated: false,
      // Gap
      branch: null,
      allBranches: null,
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      isActivating: false,
      branchToDelete: null,
      activationBranch: null,
      activationType: null,
      fetchModel: {
        branchName: null,
        branchState: "Active",
        pageIndex: 1,
        pageSize: 20,
      },
      pagesizes: [
        {
          size: 10,
        },
        {
          size: 20,
        },
        {
          size: 50,
        },
      ],
      customFields: [],
      form: {
        branchId: "",
        branchName: "",
        branchEmailaddress: "",
        branchPhonenumber: "",
        branchNotes: "",
        branchState: "Active",
      },
      formErr: {
        branchId: false,
        branchName: false,
        branchEmailaddress: false,
        branchPhonenumber: false,
        branchNotes: false,
        branchState: false,
      },
      editForm: {
        branchKey: "",
        branchId: "",
        branchName: "",
        branchEmailaddress: "",
        branchPhonenumber: "",
        branchNotes: "",
        branchState: "",
      },
      editFormErr: {
        branchKey: false,
        branchId: false,
        branchName: false,
        branchEmailaddress: false,
        branchPhonenumber: false,
        branchNotes: false,
        branchState: false,
      },
    };
  },
  methods: {
    goToDashboard() {
      this.$router.push({ path: '/dashboard' });
    },
    async getBranches() {
      try {
        this.allBranches = [];
        this.isLoading = true;
        const res = await branchesService.get(this.fetchModel);
        this.allBranches = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    changeCurrentPage(pages = null) {
      if (pages === "next") {
        this.fetchModel.pageIndex = this.allBranches.hasNextPage
          ? this.allBranches.pageIndex + 1
          : 0;
        this.getBranches();
      } else if (pages === 'prev') {
          this.fetchModel.pageIndex = this.allBranches.hasPreviousPAge
            ? this.allBranches.pageIndex - 1
            : 0;
          this.getBranches();
      } else if (pages !== null) {
        this.fetchModel.pageIndex = pages;
        this.getBranches();
      }
    },
    getBranchByKey(key) {
      try {
        this.isLoading = true;
        branchesService.getByKey(key).then((res) => {
          this.editForm = res.data.data;
          const customFieldValues = this.editForm.customFieldSetFieldsAndValue;
          customFieldValues.forEach((data) => {
            this.customFields.forEach((fields) => {
              if (fields.fieldSetKey === data.customFieldKey) {
                fields.customFields.forEach((res) => {
                  data.fields.forEach((result) => {
                    if (res.customFieldKey === result.fieldKey) {
                      document.getElementById(result.fieldKey).value =
                        result.value;
                    }
                  });
                });
              }
            });
          });
          this.isLoading = false;
        });
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    editBranch(branch) {
      try {
        this.clearForm();
        this.isLoading = true;
        this.branch = branch;
        this.editForm = {
          branchKey: branch.branchKey,
          branchId: branch.branchId,
          branchName: branch.branchName,
          branchEmailaddress: branch.branchEmailaddress,
          branchPhonenumber: branch.branchPhonenumber,
          branchNotes: branch.branchNotes,
          branchState: branch.branchState,
        };
        this.isLoading = false;
        this.getBranchByKey(branch.branchKey);
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    saveForm: async function (e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          this.form.customFieldValues = [];
          if (this.customFields.length > 0) {
            this.customFields.forEach((formSet) => {
              formSet.customFields.forEach((form) => {
                let key = form.customFieldKey;
                let value = document.getElementById(`new-${key}`).value;

                const formValObj = {
                  customFieldKey: key,
                  value,
                };
                this.form.customFieldValues.push(formValObj);
              });
            });
          }
          await branchesService.create(this.form);
          this.getBranches();
          this.clearForm();
          document.getElementById("formCloseButton").click();
          this.alertSuccess("New branch added.");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.data || e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    saveEditForm: async function (e) {
      e.preventDefault();
      if (this.validateEditForm()) {
        try {
          this.isSaving = true;
          this.editForm.customFieldValues = [];
          if (this.customFields.length > 0) {
            this.customFields.forEach((formSet) => {
              formSet.customFields.forEach((form) => {
                let key = form.customFieldKey;
                let value = document.getElementById(key).value;

                const formValObj = {
                  customFieldKey: key,
                  value,
                };
                this.editForm.customFieldValues.push(formValObj);
              });
            });
          }
          await branchesService.update(this.editForm.branchKey, this.editForm);
          this.getBranches();
          this.clearForm();
          document.getElementById("formCloseButton2").click();
          this.alertSuccess("Branch updated.");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.data || e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    openDeleteConfirmation(branch) {
      this.branchToDelete = branch;
    },
    async deleteBranch() {
      if (this.branchToDelete != null) {
        this.isDeleting = true;
        try {
          await branchesService.delete(this.branchToDelete.branchKey);
          this.branchToDelete = null;
          document.getElementById("deleteCloseButton").click();
          this.alertSuccess("Branch deleted");
          this.isDeleting = false;
          this.getBranches();
        } catch (e) {
          this.isDeleting = false;
          const error = e.response.data.message || "Something went wrong";
          this.alertError(error);
        }
      }
    },
    openActivationConfirmation(branch, type) {
      this.activationBranch = branch;
      this.activationType = type;
    },
    async activateBranch() {
      if (this.activationBranch != null) {
        this.isActivating = true;
        try {
          if (this.activationType == "deactivate") {
            await branchesService.deactivate(this.activationBranch.branchKey);
            this.activationBranch = null;
            document.getElementById("activationCloseButton").click();
            this.alertSuccess("Branch deactivated");
          } else {
            await branchesService.activate(this.activationBranch.branchKey);
            this.activationBranch = null;
            document.getElementById("activationCloseButton").click();
            this.alertSuccess("Branch activated");
          }
          this.isActivating = false;
          this.getBranches();
        } catch (e) {
          this.isActivating = false;
          const error = e.response.data.message || "Something went wrong";
          this.alertError(error);
        }
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    async getCustomFormFields(type) {
      try {
        this.isLoading = true;
        const model = {
          customFieldSetType: type,
        };
        const res = await fieldSetService.getFormFieldsByType(model);
        this.customFields = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    clearForm: function () {
      this.form = {
        branchId: "",
        branchName: "",
        branchEmailaddress: "",
        branchPhonenumber: "",
        branchNotes: "",
        branchState: "Active",
      };
      this.editForm = {
        branchKey: "",
        branchName: "",
        branchEmailaddress: "",
        branchPhonenumber: "",
        branchNotes: "",
        branchState: "",
      };
      this.getCustomFormFields("Branches");
    },
    clearFormValidation() {
      this.formErr = {
        branchId: false,
        branchName: false,
        branchEmailaddress: false,
        branchPhonenumber: false,
        branchNotes: false,
        branchState: false,
      };
    },
    clearEditFormValidation() {
      this.editFormErr = {
        branchKey: false,
        branchId: false,
        branchName: false,
        branchEmailaddress: false,
        branchPhonenumber: false,
        branchNotes: false,
        branchState: false,
      };
    },
    validateForm() {
      this.clearFormValidation();
      if (
        this.form.branchName &&
        this.form.branchEmailaddress &&
        this.form.branchId &&
        this.form.branchState
      ) {
        return true;
      }

      if (!this.form.branchName) {
        this.formErr.branchName = true;
      }
      if (!this.form.branchEmailaddress) {
        this.formErr.branchEmailaddress = true;
      }
      if (!this.form.branchId) {
        this.formErr.branchId = true;
      }
      if (!this.form.branchState) {
        this.formErr.branchState = true;
      }
      return false;
    },
    validateEditForm() {
      this.clearEditFormValidation();
      if (
        this.editForm.branchName &&
        this.editForm.branchId &&
        this.editForm.branchEmailaddress &&
        this.editForm.branchState
      ) {
        return true;
      }

      if (!this.editForm.branchName) {
        this.editFormErr.branchName = true;
      }
      if (!this.editForm.branchId) {
        this.editFormErr.branchId = true;
      }
      if (!this.editForm.branchEmailaddress) {
        this.editFormErr.branchEmailaddress = true;
      }
      if (!this.editForm.branchState) {
        this.editFormErr.branchState = true;
      }

      return false;
    },
    getDate(s) {
      try {
        let d = new Date(s);
        const yyyy = `${d.getFullYear()}`;
        const mm = `0${d.getMonth() + 1}`.slice(-2);
        const dd = `0${d.getDate()}`.slice(-2);
        return `${yyyy}-${mm}-${dd}`;
      } catch (error) {
        return false;
      }
    },
    switchShowDeactivated() {
      this.showDeactivated = !this.showDeactivated;
      if (this.showDeactivated) {
        this.fetchModel.branchState = null;
      } else {
        this.fetchModel.branchState = "Active";
      }
      this.getBranches();
    },
    validateEmailAddress(email) {
      const emailRegex = /\S+@\S+\.\S+/;
      const valid = email.trim().match(emailRegex);
      if (!valid) {
        this.formErr.branchEmailaddress = true;
        return this.alertWarning('Please provide a valid email');
      }
      this.formErr.branchEmailaddress = false;
    }
  },
  computed: {
    ...mapState({ 
      user: (state) => state.auth.user,
    }),
    buttonDisabled() {
      return this.formErr.branchEmailaddress == true;
    },
    userCanViewBranches() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Branch);
      const { permissionsList } = user;
      return permissionsList.CanViewBranch;
    },
    userCanEditBranches() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Branch);
      const { permissionsList } = user;
      return permissionsList.CanEditBranch;
    },
    userCanCreateBranches() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Branch);
      const { permissionsList } = user;
      return permissionsList.CanCreateBranch;
    },
    userCanActivateBranches() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Branch);
      const { permissionsList } = user;
      return permissionsList.CanActivateBranch;
    },
    userCanDeactivateBranches() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Branch);
      const { permissionsList } = user;
      return permissionsList.CanDeactivateBranch;
    },
  },
  async created() {
    this.getBranches();
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
});
</script>
